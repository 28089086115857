import React from 'react';
import { Image } from 'react-bootstrap'

import AppContext from "../app/AppContext"
import AdsShow from "./AdsShow"
import FullScreenMessage from "./FullScreenMessage"
import Socials from "../framework/Socials";
import Settings from "../app/settings";

export class BreakingTime extends React.Component {
    render() {
        const ads = (
            <AdsShow delay="5000">
                <div>
                    <FullScreenMessage text={(<Image src={Settings.project.logo.original} alt="Logo HQ" />)} />
                </div>
                {/* Duplicated item because AdsShow expects multiple elements */}

                {/* <div>
                    <Image className="breaking-time-ad" src={AppContext.s["intermission-slide-2"]} alt="Intermission" />
                </div> */}
            </AdsShow>
        );

        return (
            <div className="breaking-time">
                <div className="content" ref={this.contentRef}>
                    {/* {ads} */}
                    
                    <div className="ads-show">
                        <div className='item selected'>
                            <FullScreenMessage text={(<Image src={"/assets/iqspace/images/logo-text-white.png"} alt="IQ Space" />)} />
                        </div>
                    </div>
                </div>

                <p className="text-center">{AppContext.r["become-a-quizmaster"]}</p>

                <p><a href={`http://${Settings.project.web.domain}`} target='_blank'>{Settings.project.web.site}</a></p>
                <p className="text-center">{AppContext.r["like-us-desc"]}</p>

                <Socials />
            </div>
        );
    }
}
