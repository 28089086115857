import React from "react";
import { connect } from 'react-redux';
import * as yup from 'yup';

import { useUderlyStore } from "@uderly/react-uderly-ui/zustand";
import AppContext from 'app/AppContext';
import Module from 'framework/Module';
import { withModal } from 'framework/withModal';
import { setAlert } from '../../redux/layoutSlice';
import Settings from '../../app/settings';

class Followers extends React.Component {
    state = {
        store: null,
        isPremium: null
    };

    moduleRef = React.createRef();

    apiPath = "/users";

    get initialValues() {
        return {
            title: "",
            published: true
        };
    }

    get schema() {
        const v = {
            title: yup.string().required(AppContext.r["required-field"])
        };

        return yup.object(v);
    }

    get tableHead() {
        return [
            {
                "Title": AppContext.r["gamename"],
                "Adapter": (o) => {
                    return (
                        <div className="td-v-center">
                            <div className="row-thumb">
                                {o.image && <>
                                    <img src={((o.image.disk === "net") ? Settings.apiUrl : AppContext.s["api-url"]) + this.apiPath + "/" + o.id + "/image/thumb"} alt="Thumb" />
                                </>}
                                {!o.image && <img src={AppContext.s["placeholder"]} className="placeholder-thumb" alt="Thumb" />}
                            </div>

                            {o.gamename && o.gamename.length > 0 ? o.gamename : AppContext.r['anonymous-player']}
                        </div>);
                }
            },
            // {
            //     Title: AppContext.r["name"],
            //     Field: "name"
            // },
            // {
            //     Title: AppContext.r["surname"],
            //     Field: "surname"
            // },
            // {
            //     Title: AppContext.r["phone"],
            //     Adapter: (o) => {
            //         if (!this.state.isPremium)
            //             return AppContext.r["premium-feature"];
            //         if (!o.allow_newsletter)
            //             return AppContext.r["hidden-by-user"];

            //         return o.mobile_phone ? <a href={"tel:" + o.mobile_phone}>{o.mobile_phone}</a> : "";
            //     }
            // },
            // {
            //     Title: AppContext.r["email"],
            //     Adapter: (o) => {
            //         if (!this.state.isPremium)
            //             return AppContext.r["premium-feature"];
            //         if (!o.allow_newsletter)
            //             return AppContext.r["hidden-by-user"];

            //         return o.email ? <a href={"mailto:" + o.email}>{o.email}</a> : "";
            //     }
            // }
        ];
    }

    async componentDidMount() {
        const stores = useUderlyStore.getState().stores;
        console.log(stores)

        // if(response && response.status === 200) {
        //     const store = response.data.data;

        if (stores && stores[0])
            this.setState({
                store: stores[0],
                isPremium: stores[0].premium_id > 0 && (!stores[0].premium_ends_in || stores[0].premium_ends_in > 0)
            });
        // }
    }

    render() {
        const { store } = this.state;

        return (
            <div className="page module followers">
                {store &&
                    <Module ref={this.moduleRef}
                        apiUrl={Settings.apiUrl}
                        modal={this.props.modal} setAlert={this.props.setAlert}
                        apiPath={"/followers?store_id=" + store.id}
                        path={"/followers"} friendlyPath={"followers"}
                        store={store} id={this.props.id} tableHead={this.tableHead}
                        view={this.view} viewClassName="event-mask"
                        disableView disableInsert disableEdit disableDelete
                        authActions={this.props.authActions} />}
            </div>
        );
    }
}

const mapDispatchToProps = () => ({
    setAlert
});

export default connect(null, mapDispatchToProps())(withModal(Followers));