import React, { ReactNodel, useState } from "react";
import { QRCode } from 'react-qrcode-logo';
import { Link } from "react-router-dom";

import Countdown from './Countdown';
import InterestedButton from "../app/components/InterestedButton";
import { BuyTicketButton } from "../app/components/BuyTicket";
import { IqEvent } from "../models/Event";
import GameStatus from "../models/GameStatus";
import AppContext from '../app/AppContext';
import Settings from "../app/settings";
import { ViewModeStatesEnum, gameStatusToClassName } from "./Game";
import GameStatusEnum from "./GameStatusEnum";

export interface PreEventProps {
	event: IqEvent;
	viewMode: number;
	modal: any; // TODEL
	authActions: any; // TODEL
	gameStatus: GameStatus;
}

const titleSplitter = (title: string) => {
	const jsx: ReactNode[] = [];
	const words = title.split(" ");

	const lineLength = Math.min(10, title.length / 2);

	let line = "";

	for (let index in words) {
		const word = words[index];

		line += (word + " ");

		if (line.length >= lineLength || parseInt(index) === (words.length - 1)) {
			jsx.push(<h1>{line}</h1>);
			line = "";
		}
	}

	return jsx;
}

const StartView = ({ event, gameStatus, viewMode, modal, authActions }: PreEventProps) => {
	const [animated, setAnimated] = useState(false);

	const spaceUrl = `${Settings.project.web!.domain}/${event.store.subdomain}`;

	const onAnimationEnd = () => {
		console.log("onAnimationEnd");

		// setAnimated(false);
	}

	return (<div className={`start-view ${gameStatusToClassName(gameStatus)} ${animated ? "animated" : ""}`}>
		<div>
			{viewMode === ViewModeStatesEnum.Recorder &&
				<>
					<img className="event-image" onAnimationEnd={onAnimationEnd}
						src={Settings.apiUrl + "/events/" + event.id + "/image"} alt={event.title} />

					<div className="split-title">{titleSplitter(event.title)}</div>
				</>}

			{viewMode !== ViewModeStatesEnum.Recorder &&
				<>
					<div>
						{!event.published &&
							<p className="glamour-info-box">{AppContext.r["event-not-published"]}</p>}
					</div>

					<div>
						{(event.media && event.media.length > 0) &&
							<img className="event-image"
								src={Settings.apiUrl + "/events/" + event.id + "/image"} alt={event.title} />}

						<div className="split-title">{titleSplitter(event.title)}</div>
					</div>

					<div>
						<Countdown timespan={event.countdown}
							showLabel={true} label={AppContext.r['on-air-in']} />

						{viewMode === ViewModeStatesEnum.Mobile &&
							<div className="game-actions">
								<InterestedButton eventAttendance={event.myAttendance} event={event}
									authActions={authActions} />

								<BuyTicketButton event={event} game={gameStatus} store={event.store}
									authActions={authActions} modal={modal} />
							</div>}

						<div className="game-hosted-by">
							<p>{AppContext.r['game-hosted-by']}</p>
							<Link to={"/" + event.store.subdomain}>{event.store.name}</Link>
						</div>
					</div>
				</>}

			{/* {viewMode === ViewModeStatesEnum.Recorder &&
				<img className="event-image"
					src={Settings.apiUrl + "/events/" + event.id + "/image"} alt={event.title} />} */}
		</div>

		{viewMode === ViewModeStatesEnum.BigScreen &&
			<div className="invite-players">
				<p>{AppContext.r['access-instructions']}</p>
				<h1>{spaceUrl}</h1>
				<p>{AppContext.r['access-instructions-qr']}</p>

				<QRCode value={"https://" + spaceUrl} size={150}
					logoImage={Settings.project.logo.mini} logoWidth={150 * 0.3} />
			</div>}

	</div>);
}

export default StartView;