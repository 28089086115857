import React from 'react';
import Button from "@mui/material/Button";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import { createItemRequest } from '@uderly/react-uderly-ui/api';
import AppContext from 'app/AppContext';
import IqSpaceApi from 'api/IqSpace';
import JsonToTable from 'framework/JsonToTable';
import Settings from '../../app/settings';

export default class PlaylistsSelect extends React.Component {
    state = {
        records: null,
        showInsertPlaylist: false,
        newPlaylistName: "",
        isInserting: false
    };

    apiPath = "/playlists";

    async componentDidMount() {
        const response = await IqSpaceApi.Playlists();
        const {exclude} = this.props;

        if(response) {
            const { data } = response.data;

            this.setState({
                records: exclude ? data.filter(x => x.id !== exclude.id) : data
            });
        }
    }

    get tableHead () {
        return [
            {
                Title: AppContext.r["name"],
                Adapter: (o) => {
                    return (
                        <div className="td-v-center">
                            <div className="row-thumb">
                                {(o.media && o.media[0]) && <>
                                    <img src={((o.media[0].disk === "net") ? Settings.apiUrl : AppContext.s["api-url"]) + this.apiPath + "/" + o.id + "/image/thumb"} alt="Thumb" />
                                </> }
                                {!o.media || !o.media[0] && <img src={AppContext.s["placeholder"]} className="placeholder-thumb" alt="Thumb" /> }
                            </div>

                            {o.name}
                        </div>);
                }
            },
            {
                Adapter: (o) => { 
                    return <div className="td-v-center">{o.questions_count + " " + AppContext.r["questions"]}</div>;
                }
            }
        ];
    }

    onSelect = (o) => {
        this.setState({
            isLoading: true
        });

        if(this.props.onSelect)
            this.props.onSelect(o);
            
        this.setState({
            isLoading: false
        });
    }

    onInsertPlaylist = () => {
        this.setState({
            showInsertPlaylist: true
        });
    }

    onInsertToNewPlaylist = async () => {
        const { newPlaylistName } = this.state;

        this.setState({
            isInserting: true
        });

        const response = await createItemRequest(Settings.apiUrl + "/playlists", {
            name: newPlaylistName
        });

        if(response && response.status > 200)
            if(this.props.onSelect)
                this.props.onSelect(response.data.data);

        this.setState({
            isInserting: false
        });
    }

    render () {
        const { records, showInsertPlaylist, newPlaylistName, isLoading } = this.state;

        if(!records || isLoading)
            return <div className="preloader-container">{AppContext.r["preloader"]}</div>;

        return <>
            <JsonToTable head={this.tableHead} body={records} onRowClick={this.onSelect} />
            
            {!showInsertPlaylist ? 
                <Button onClick={this.onInsertPlaylist}>{AppContext.r["new-playlist"]}</Button>
                : <div className="quick-new-playlist">
                    <TextField variant="outlined" label={AppContext.r["name"]}
                        value={newPlaylistName} onChange={(event) => this.setState({
                            newPlaylistName: event.target.value
                        })} />
                    
                    <Box marginLeft={1}>
                        <Button onClick={this.onInsertToNewPlaylist}>{AppContext.r["insert"]}</Button>
                    </Box>
                </div>}
        </>;
    }
}