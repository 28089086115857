import { ISettings, AuthTypes } from "@uderly/react-uderly-ui/models";
// import { AuthTypes } from "../models/AuthConfig";

interface IqSpaceSettings extends ISettings {
  maxPlaylistQuestions: number;
}

const Settings: IqSpaceSettings = {
  storeId: 2,
  provider: {
    title: "Uderly",
    company: { name: "Uderly Ltd", address: "520 Birchwood Boulevard, Birchwood, Warrington, United Kingdom, WA3 7QX" },
    web: {
      title: "Uderly",
      domain: "uderly.com",
      site: "uderly.com"
    }
  },
  project: {
    id: "iqspace",
    title: "IQ Space",
    web: {
      title: "IQ Space",
      domain: "iqspace.live",
      site: "www.iqspace.live"
    },
    logo: {
      original: "/assets/iqspace/images/logo.png",
      originalDark: "/assets/iqspace/images/logo.png",
      mini: "/assets/iqspace/images/logo.png",
      miniDark: "/assets/iqspace/images/logo.png"
    }
  },
  auth: {
    type: AuthTypes.oauth2,
    clientId: "2",
    clientSecret: "0dIONiMg3HBDq6AID9dkFXxu8gNeWBQ6XLfxQtdu"
  },
  stripe: {
    key: "pk_live_51ITrkbG9FiCTI7YqVdVNptG2rRm57v3R5xnfw4JZK3BLRW6RZE1rJE0JwnDwZbOwvP5JEM8gnQEezE56vlxNSzuX00qjXClR1v",
    testKey: "pk_test_51ITrkbG9FiCTI7Yqf9vWy3mK8qiCMhrRcXHBm8GhpaSXkmrQsyoZDMCFMIKphJMQplftj8HFRtbg22hAB4gWrOdY00yzSXqjyc"
  },
  localhostUrl: "http://localhost:3000",
  apiUrl: "https://iqspace-api.net.uderly.com",
  // apiUrl: "https://iqspace-test-api.net.uderly.com",
  // apiUrl: "https://localhost:7042",
  isLocalhost: () => window.location.hostname === "localhost",
  cookies: true,
  maxPlaylistQuestions: 100,
  siteMap: undefined // Defined externally
};

export default Settings;
