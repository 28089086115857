import React, { Component } from 'react';

export default class AdsShow extends Component {
    constructor(props) {
        super(props);

        const d = (props.delay !== undefined) ? props.delay : 5000;

        this.state = {
            currentIndex: (props.children.length > 0) ? 0 : -1,
            delay: d
        };

        this.interval = null;
    }

    componentDidMount() {
        const t = this;

        const count = t.props.children.length;

        if (this.interval === null)
            this.interval = setInterval(function () {
                const nextIndex = ((t.state.currentIndex + 1) < count) ? (t.state.currentIndex + 1) : 0;
                //console.log(t.props.children.length, "Displaying index " + nextIndex);

                t.setState({
                    currentIndex: nextIndex
                })
            }, t.state.delay);
    }

    componentWillUnmount() {
        if (this.interval !== null)
            clearInterval(this.interval);
    }

    render() {
        var i = 0;
        const items = this.props.children.map((item) => {
            const selected = (this.state.currentIndex === i++) ? " selected" : "";
            return (<div className={"item" + selected} key={i}>{item}</div>)
        });
        // return (this.state.currentIndex === i++) ? (<div className="item selected">{item}</div>) : (<div className="item">{item}</div>)

        return (
            <div className="ads-show">
                {items}
            </div>
        );
    }
}
