import React from 'react';
import { Link } from "react-router-dom";
import CheckIcon from '@mui/icons-material/Check';

import Settings from '../../settingsOld';

export default function loadClientResources(resources) {
    resources['tag-line'] = <>IQ Space è il nuovo <b>live quiz</b> che manderà in orbita il tuo business di intrattenimento.</>;
    resources["settings"] = "Impostazioni";

    resources['game-hosted-by'] = "Questo gioco è presentato da";
    resources['access-the-game'] = "Accedi al gioco";
    resources['ready-for-challenge'] = "Pronto per una sfida?";
    resources['wanna-change-name'] = "Vuoi cambiare il nome di gioco?";
    resources['whats-game-name'] = "Quindi, quale sarà il tuo nome di gioco?";
    resources['more-account-settings'] = "Scegli un'immagine di profilo e altre impostazioni";
    resources['last-events'] = "Eventi Passati";
    resources['game-started'] = "GIOCO INIZIATO";
    resources['game-completed'] = "GIOCO COMPLETATO";
    resources['new-game-starting-soon'] = "Un nuovo gioco inizierà a momenti.";
    resources['congrats-winners'] = "Congratulazioni ai vincitori";
    resources['join-the-game'] = "ACCEDI AL GIOCO";
    resources['game-not-existing'] = "Il gioco selezionato non esiste o non è stato ancora avviato.";
    resources['event-not-existing'] = "Questo evento non esiste. :/";
    resources['event-not-published'] = "Questo evento non è stato ancora pubblicato e stai visualizzando un'anteprima visibile solo da te.";
    resources['could-not-fetch-event'] = <>Non è stato possibile caricare l'evento.<br />Per favore, controlla la tua connessione a internet e riprova.</>;
    resources['game-expired'] = "Il gioco selezionato è scaduto.";
    resources['game-stopped'] = "Il gioco selezionato è stato fermato dall'intrattenitore.";
    resources['game-stopped'] = "";
    resources['ranking-position'] = "Sei arrivato in posizione {0} in classifica con un punteggio di {1} punti.";
    resources['click-here'] = "clicca qui";
    resources['browse-to-event-page'] = " per vedere la classifica finale e la pagina dell'evento.";
    resources['show-leaderboard'] = "Mostra Classifica";
    resources['share-leaderboard'] = "Condividi Classifica";

    resources["keep-up-to-date"] = "Tieni aggiornato il tuo profilo pubblico";
    resources["upcoming-events"] = "Eventi in programma";
    resources['upcoming-event'] = "Prossimo evento";
    resources["no-upcoming-events"] = "Nessun evento in programma";
    resources["playing-now"] = "Giochi in corso";

    resources["events"] = "Eventi";
    resources["event"] = "Evento";
    resources["title"] = "Titolo";
    resources["published"] = "Pubblico";
    resources["datetime"] = "Data & Ora";
    resources["timezone"] = "Fuso Orario";
    resources["youtube-url"] = "Link YouTube";
    resources["facebook-url"] = "Link Facebook";
    resources["instagram-url"] = "Link Instagram";
    resources["twitter-url"] = "Link Twitter";
    resources["event-link"] = "Link Evento";

    resources["packages"] = "Contenuti";
    resources["package"] = "Contenuto";

    resources["games"] = "Giochi";
    resources["game"] = "Gioco";
    resources["event-id"] = "Id Evento";
    resources["started"] = "Iniziato";
    resources["stopped"] = "Fermato";
    resources["duration"] = "Durata";
    resources["paused"] = "In Pausa";
    resources["status"] = "Stato";

    resources["categories"] = "Categorie";
    resources["category"] = "Categoria";
    resources["color"] = "Colore";
    resources["points"] = "Punti";

    resources["questions"] = "Domande";
    resources["question"] = "Domanda";
    resources["text"] = "Testo";
    resources["note"] = "Note";
    resources["answers"] = "Risposte";
    resources["answer"] = "Risposta";
    resources["image-duration"] = "Durata Immagine";
    resources["collection-not-editable"] = "Questa collezione non è modificabile.";
    resources["empty-answer"] = "Questa risposta è vuota.";

    resources['exact-one'] = "Esatta";
    resources['wrong'] = "Errate";
    resources['not-answered'] = "Astenuti";
    resources['player'] = "Giocatore";
    resources['players'] = "Giocatori";
    resources['partial-ranking'] = "Classifica Parziale Ultima Domanda";
    resources['ranking'] = "Classifica Generale";
    resources['final-ranking'] = "Classifica Finale";
    resources['sorry'] = "Spiacente!";
    resources['access-instructions'] = "Per giocare accedi a ";
    resources['access-instructions-qr'] = "o scansiona il codice QR.";
    resources['game-started-full'] = (
        <div>
            <h1>Mettiti Comodo</h1>
            <p>Che la sfida abbia inizio</p>
        </div>);

    resources['play'] = "Gioca";
    resources['join-the-game'] = "ACCEDI AL GIOCO";
    resources['cookie-terms'] = "Accedendo accetti l'utilizzo dei cookies, necessari al funzionamento del gioco.";
    resources['quit'] = "Abbandona il gioco";
    resources['insert-name'] = "Inserisci il tuo nome";

    resources['welcome-enabled'] = (<div>
        <p>Benvenuto, sei entrato nel gioco.</p>
        <p>Inizierai con la prossima domanda.</p>
        <p className='internet-connection-box'>Per partecipare hai bisogno di una connessione internet affidabile. Per ridurre l'uso di dati, potresti connetterti alla Wi-Fi del locale.</p>
        <p>Buon divertimento!</p>
    </div>);
    resources['welcome-pending'] = "<p>Hey <span className=\"teamName\">&nbsp;</span>, ci siamo quasi!</p><p>Informa l'intrattenitore che desideri partecipare al gioco per approvare la tua registrazione.</p>";
    resources['name-taken'] = "Non è possibile scegliere il nome che hai specificato perchè è stato già utilizzato da qualcun altro.";
    resources['max-players-count-reached'] = <>Non è possibile accedere al gioco in quanto <br />il <b>numero massimo</b> di giocatori è stato raggiunto.</>;
    resources['name-error'] = "Per accedere al gioco dovresti specificare un nome che ti rappresenti o che rappresenti la tua squadra.";
    resources['you-scored'] = "Hai totalizzato ";
    resources['confirm-quit-game'] = "Uscendo dal gioco perderai i progressi e il punteggio guadagnati. Sei sicuro di voler continuare?";

    resources['logged-on-other-device'] = <>IqSpace è aperto in un'altra finestra.<br />Clicca \"gioca\" per ri-accedere al gioco in questa finestra.</>;
    resources['kicked-out'] = "Sei stato rimosso dal gioco dall'amministrazione.";
    resources['quiz-rules'] = "Regolamento Quiz";
    resources['on-air-in'] = "In onda tra...";

    resources["user-area"] = "Area Utente";
    resources["quizmaster-portal"] = "Portale Intrattenitori";

    resources["use-promo-code"] = <>Usa il codice promozionale <b>PRO100</b> per ottenere una licenza Pro gratuita per 3 mesi.</>;
    resources["already-joined"] = <>
        <p>Sembra che tu faccia già parte della nostra community.
            <br />Vai alla tua pagina per gestire i tuoi eventi di gioco.</p></>
    resources["wants-to-join-community"] = "Vuoi far parte della nostra community?";
    resources["account-needed"] = "Registra un account con noi per iniziare. E' gratis, naturalmente!";
    resources["fill-the-form-to-join"] = "Compila il form per diventare una stella di " + Settings["project-name"] + ".";
    resources["joined-congrats"] = "Congratulazioni 🥳";
    resources["joined-description"] = <>
        <p>Perfetto!</p>
        <p>La tua richiesta di affiliazione è andata a buon fine.</p>
        <p>E' tempo di creare il tuo primo evento di gioco.</p></>
    resources["joined-failed"] = "Richiesta Fallita";
    resources["subdomain-taken"] = "L'indirizzo dello spazio richiesto è stato già preso o riservato. Prova un altro nome.";
    resources["invalid-promo-code"] = "Il codice promozionale inserito non è valido.";
    resources["joined-failed-description"] = <><p>Qualcosa è andato storto e la tua richiesta non è andata a buon fine.</p><p><Link to="/contact">Contattaci</Link> e saremo lieti di aiutarti a impostare il tuo account con noi.</p></>;
    resources["submit-request"] = "Invia Richiesta";
    resources["pagename"] = "Nome Pagina";
    resources["promo-code"] = "Codice Promozionale";
    resources["your-space"] = "Il Tuo Spazio";
    resources["current-games"] = "Giochi In Corso";
    resources["your-space-desc"] = <><small>Da nostro intrattenitore affiliato, avrai la tua pagina su IQ Space, che visualizzerà gli eventi e altre informazioni su di te.</small>
        <br /><small>Grande, no? 😎</small></>;
    resources["your-events-will-be"] = "I tuoi eventi saranno presentati sulla pagina";
    resources["nickname-validation"] = "Il nome della tua pagina deve essere lungo almeno 6 caratteri e può contenere soltanto lettere minuscole, numeri e trattini.";
    resources["gamename-validation"] = "Il tuo nome di gioco deve essere lungo almeno 3 caratteri.";
    resources["organization-name"] = "Nome della tua organizzazione";
    resources["must-reconnect"] = "Si è verificato un problema e non è stato possibile avviare il gioco.";
    resources["reload-game"] = "Ricarica";
    resources["login-to-play"] = "per giocare.";
    resources["game-option-quit-tooltip"] = "Abbandona Il Gioco";
    resources["game-option-sounds-on-tooltip"] = "Abilita Suoni";
    resources["game-option-sounds-off-tooltip"] = "Disabilita Suoni";
    resources["game-option-fullscreen-on-tooltip"] = "Abilita Fullscreen";
    resources["game-option-fullscreen-off-tooltip"] = "Disabilita Fullscreen";
    resources["game-option-players-tooltip"] = "Mostra Giocatori";

    resources["past-events"] = "Eventi Passati";
    resources["no-past-events"] = "Nessun evento passato";
    resources["enable-audio"] = "Abilita Audio";
    resources["disable-audio"] = "Disabilita Audio";
    resources["powered-by"] = "powered by";
    resources["like-us-desc"] = <>Segui le nostre pagine per tenerti<br />informato sui nostri prossimi eventi.</>;
    resources["like-this-game"] = <><p>Ti è piaciuto questo gioco?</p><p>{resources["like-us-desc"]}</p></>;
    resources["no-chat-messages"] = <>Scrivi qualcosa per primo in chat. 😉</>;
    resources["access-to-chat"] = "Accedi al gioco per chattare";
    resources["game-name"] = "Nome di Gioco";
    resources["game-name-desc"] = "Questo è il tuo nome pubblico per giocare.";

    resources["home-banner"] =
        (<>
            <h1>IQ Space sta arrivando</h1>
            <p>IQ Space è il nuovo <b>live quiz</b> per mandare in orbita 🚀 il tuo business di intrattenimento.</p>
            <p>IQ Space integra <b>Zoom</b> per coinvolgere in real-time i giocatori e una <b>chat social</b> per interagire tra di loro.</p>
            {/* <p>Start to sell your tickets directly from the IQ Space Portal ticketing system.</p> */}
            <p><Link to="workwithus">Inizia adesso a <s>lavorare</s> giocare con noi.</Link></p>
        </>);
    resources["buy-ticket"] = "Acquista Ticket";
    resources["your-tickets"] = "I tuoi ticket";
    resources["on"] = "Il";
    resources["purchased-on"] = "Acquistato Il";
    resources["ticket-id"] = "Id Ticket";
    resources["voucher-code"] = "Voucher";
    resources["paid"] = "Acquistato";
    resources["refunded"] = "Rimborsato";
    resources["refunds"] = "Rimborsi";
    resources["start-button"] = <span>Inizia (gratis) a <s>lavorare</s> giocare con noi</span>;
    resources["not-found"] = <>Pagina non trovata.<br />Verifica se l'indirizzo url è corretto.</>;
    resources["you-already-own"] = "Hai già acquistato questo ticket.";
    resources["voucher"] = "Voucher";
    resources["have-voucher"] = "Hai un voucher?";
    resources["apply"] = "Applica";
    resources["voucher-used"] = "Questo codice è già stato utilizzato.";
    resources["voucher-not-valid"] = "Questo codice non è valido.";
    resources["got-free-ticket"] = "Congratulazioni! Hai ottenuto un ticket gratuito!";
    resources["following"] = "Segui";
    resources["follow"] = "Segui";
    resources["unfollow"] = "Non seguire più";
    resources["interested"] = "Mi interessa";
    resources["not-interested"] = "Non mi interessa";
    resources["not-following-anyone"] = "Non segui ancora nessuno.";
    resources["work-from-x"] = ["dal pub", "da casa", "dalla pizzeria", "da una spiaggia (perchè no?)", "dal tuo studio televisivo", "da dove preferisci 😍"];
    resources["allow-discoverable"] = <>Vuoi essere promosso sul nostro sito o contattato da<br />IQ Space e partner per opportunità professionali?</>;
    resources["go-to-event"] = "Vai A Evento";

    resources["meet-the-team"] = "Il Team";
    resources["iqspace-for-business"] = "IQ Space per aziende e altre organizzazioni";
    resources["iqspace-tagline"] = "L'intrattenimento è la nostra priorità, il divertimento la nostra missione. 🔥";

    resources["automate-iqspace"] = "Automatizza IQ Space";
    resources["automate-iqspace-desc"] = (
        <>
            <p className="text-center">Intrattieni con divertenti, interattivi e <b>completamente automatizzati</b> giochi in real-time.</p>
            <br /><i className="fas fa-magic icon large light-grey"></i>
            <br /><br /><br />
        </>
    );
    resources["players"] = "Giocatori";
    resources["assistance"] = "Assistenza";
    resources["get-it-now"] = "Richiedilo";
    resources["manage-playlist-yourself"] = <>Gestisci tu la<br />playlist automatizzata</>;
    resources["month"] = "mese";
    resources["for-whole"] = "Per tutto il";
    resources["if-order-by"] = "se ordini entro";
    resources["localization-options"] = "Localizzazione";
    resources['game-language'] = "Lingua del Gioco";
    resources['currency'] = "Valuta";
    resources['play-the-demo'] = "Gioca la demo";
    resources['features'] = "Caratteristiche";

    resources["payments"] = "Pagamenti";
    resources["stripe-partners"] = <>
        <p>Uderly è partner di Stripe per la gestione dei pagamenti.</p>
        <p>Connettiti con Stripe per ricevere i pagamenti sul tuo account.</p>
        <p><small>Questa azione ti reindirizzerà al form Stripe di affilazione con Uderly.</small></p>
    </>;
    resources["connect-stripe"] = "Connetti Stripe";
    resources["stripe-charges-not-enabled"] = <>Non hai completato il processo di affiliazione per ricevere pagamenti.</>
    resources["stripe-required"] = <>E' necessario impostare i tuoi dati per poter vendere e ricevere pagamenti su {Settings["project-name"]}.</>
    resources["stripe-payouts-not-enabled"] =
        <>Hai fornito tutti i dati per poter vendere su {Settings["project-name"]} ma c'è ancora qualcosa che dovresti controllare per ricevere fondi sul tuo conto corrente.
            <br />Per favore verifica il tuo account Stripe. Se pensi di aver fornito tutti i dati richiesti, non ti preoccupare e considera cortesemente che, per verificare la tua identità, Stripe potrebbe richiedere un paio di giorni lavorativi.
            <br />Puoi già iniziare ad usare {Settings["project-name"]}!</>
    resources["stripe-successfully-connected"] = "Hai connesso con successo i tuoi dati di pagamento con Stripe.";
    resources["view-stripe-account"] = "Account Stripe";
    resources["view-stripe-dashboard"] = <p>Puoi gestire i tuoi pagamenti direttamente dalla tua <a href="https://dashboard.stripe.com/" target="_blank" rel="noopener noreferrer">dashboard Stripe</a>.<br />Se desideri cambiare o rimuovere il tuo account Stripe, contattaci.</p>;


    // FROM PORTAL


    resources["active"] = "Attivo";
    resources["pricing"] = "Costo";
    resources["amount"] = "Costo";
    resources["you-earn"] = "Il tuo guadagno";
    resources["ticket-price"] = "Costo Ticket";
    resources["net-income"] = "Guadagno Netto";
    resources["transaction-fee"] = "Costo Transazione";
    resources["tickets"] = "Ticket";
    resources["no-tickets"] = "Non sembra tu abbia acquistato alcun ticket.";
    resources["free-tickets-desc"] = <>
        <p>Potresti usare questi voucher per offrire <b>ticket gratuiti</b>.</p>
        <small><p>Suggerimento: potresti darli come premio ai vincitori.</p></small></>;
    resources["ticket-min-price"] = "Importo minimo {currency} 0.50";
    resources["attendances"] = "Partecipazioni";
    resources["purchased-on"] = "Data e Ora di Acquisto";
    resources["fullname"] = "Nome Utente";
    resources["user"] = "Utente";
    resources["username"] = "Username";
    resources["voucher"] = "Voucher";
    resources["generate-free-tickets"] = "Genera Ticket Gratuiti";
    resources["attendance_status"] = "Stato";
    resources["attendance_statuses"] = {
        1: "Interessato",
        2: "Acquisito",
        3: "Intenzione di Pagamento",
        4: "Rimborsato",
    };
    resources["no-attendances"] = "Non ci sono ancora partecipazioni confermate per questo evento.";
    resources["cant-delete-event"] = <>
        <p>Non è possibile eliminare questo evento, in quanto ha delle risorse associate. Potresti avere avere, ad esempio, una sessione di gioco attiva o dei biglietti venduti.</p>
        <p>Ricorda che puoi nascondere questo evento al pubblico marcandolo come <b>non</b> pubblicato.</p>
    </>;
    resources["refund"] = "Rimborsa";
    resources["refund-ticket"] = "Rimborsa Ticket";
    resources["refund-confirm"] = "Vuoi rimborsare {user}?";
    resources["played-questions"] = "Domande Giocate";
    resources["no-played-questions"] = "Nessuna domanda è stata ancora giocata.";
    resources["total-players"] = "Giocatori";
    resources["correct-responses-count"] = "Corrette";
    resources["unanswered-responses-count"] = "Astenuti";
    resources["incorrect-responses-count"] = "Scorrette";

    resources["event-page"] = "Link Evento";
    resources["events"] = "Eventi";
    resources["event"] = "Evento";
    resources["title"] = "Titolo";
    resources["published"] = "Pubblico";
    resources["youtube-url"] = "Link YouTube";
    resources["facebook-url"] = "Link Facebook";
    resources["instagram-url"] = "Link Instagram";
    resources["twitter-url"] = "Link Twitter";
    resources["event-link"] = "Link Evento";
    resources["event-planned"] = "Evento in programma tra";
    resources["game-expired"] = <>Questo evento è scaduto.<br />Crea un nuovo evento per giocare.</>;

    resources["actions"] = "Azioni";
    resources["follower"] = "Follower";
    resources["followers"] = "Follower";
    resources["gamename"] = "Nome di Gioco";
    resources["packages"] = "Album Domande";
    resources["package"] = "Album Domande";
    resources["playlists"] = "Playlist";
    resources["playlist"] = "Playlist";
    resources["select-playlist"] = "Scegli una Playlist";
    resources["collections"] = "Collezioni";
    resources["collection"] = "Collezione";
    resources["no-playlists"] = "Nessuna playlist.";
    resources["create-playlist"] = "Crea una nuova playlist";
    resources["clear-playlist"] = "Svuota Playlist";
    resources["clear-playlist-confirm"] = "Sei sicuro di voler rimuovere tutte le domande dalla playlist?";
    resources["populate-playlist"] = "Popola Playlist";
    resources["add-to"] = "Aggiungi a";
    resources["add-to-new-playlist"] = "Aggiungi a nuova playlist";
    resources["new-playlist"] = "Nuova Playlist";
    resources["add-to-playlist"] = "Aggiungi alla playlist";
    resources["add-all-to-playlist"] = "Aggiungi tutte a playlist";
    resources["remove-from-playlist"] = "Rimuovi dalla playlist";
    resources["move-to-playlist"] = "Sposta in playlist";
    resources["copy-to-playlist"] = "Aggiungi a playlist";
    resources["remove-question"] = "Rimuovi Domanda";
    resources["remove-question-confirm"] = "Sei sicuro di voler rimuovere la domanda selezionata dalla playlist corrente?";
    resources["confirm-delete-question"] = "Sei sicuro di voler eliminare la domanda selezionata?";
    resources["drag-questions"] = "Trascina le domande per riordinare la lista";
    resources["quiz"] = "Quiz";
    resources["options"] = "Opzioni";
    resources["insert-question"] = "Inserisci Domanda";
    resources["question-options"] = "Opzioni";
    resources["add-to-collection"] = "Aggiungi alla tua collezione";
    resources["remove-from-collection"] = "Rimuovi dalla tua collezione";

    resources["video-url"] = "URL Video";
    resources["video-support"] = "Supporta YouTube, Facebook, Twitch, SoundCloud, Streamable, Vimeo, Wistia, Mixcloud, and DailyMotion.";
    resources["video-url-disabled-desc"] = <p><small>Il video non può essere mostrato se una immagine è stata specificata.</small><br /><small>Scegli se visualizzare un'immagine o un video.</small></p>;
    resources["fixed-score"] = "Punteggio Fisso";
    resources["fixed-score-default"] = <small>Algoritmo di default</small>;
    resources["fixed-score-desc"] = <small>Scegli se applicare un punteggio fisso o utilizzare l'algoritmo prestabilito per l'assegnazione di un punteggio progressivo.</small>;

    resources["games"] = "Giochi";
    resources["game"] = "Gioco";
    resources["event-id"] = "Id Evento";
    resources["started"] = "Iniziato";
    resources["stopped"] = "Fermato";
    resources["duration"] = "Durata";
    resources["max-players-count"] = "Max Num. Giocatori";
    resources["paused"] = "In Pausa";
    resources["status"] = "Stato";

    resources["categories"] = "Categorie";
    resources["category"] = "Categoria";
    resources["color"] = "Colore";

    resources["questions-count"] = "Numero Domande";
    resources["questions"] = "Domande";
    resources["question"] = "Domanda";
    resources["text"] = "Testo";
    resources["note"] = "Note";
    resources["answers"] = "Risposte";
    resources["answer"] = "Risposta";
    resources["image-duration"] = "Durata Immagine";
    resources["collection-not-editable"] = "Questa collezione non è modificabile.";

    resources["start-when"] = "Avvia il gioco quando sei pronto";
    resources["events-today"] = "Eventi di oggi"; // TODEL
    resources["no-events-today"] = "Niente in programma per oggi."; // TODEL
    resources["start-new-game"] = "Avvia un nuovo gioco"; // TODEL
    resources["start-game"] = "Avvia gioco"; // TODEL
    resources["stop-game"] = "Ferma gioco";
    resources["plan-an-event"] = "programma un evento"; // TODEL

    resources["this-is-a-free-event"] = "Evento gratuito.";
    resources["this-is-a-paid-event"] = "Evento a pagamento.";
    resources["licence"] = "Licenza";
    resources["free-not-possible"] = <>
        <p>Non è più possibile rendere questo evento gratuito, in quanto hai già venduto dei ticket o avviato il gioco.</p>
        <p>Se desideri ospitare un gioco gratuito, considera di rimborsare i giocatori e creare un nuovo evento.</p>
    </>;

    // Squizzer Real Time Server Messages
    resources["rts-connecting"] = "Connessione";
    resources["rts-connected"] = "Connesso. :) Registrazione al server...";
    resources["rts-disconnected"] = "Disconnesso. :( Riconnessione in corso...";
    resources["rts-couldnt-connect"] = "Il gioco non è disponibile al momento per via di un problema di connessione al server. Assicurati di avere una connessione a internet affidabile e prova a ricaricare la pagina tra un po'.";
    resources["rts-subscription-failed"] = "Registrazione al server fallita. Errore: ";

    resources["cancel-by"] = "Elimina entro";
    resources["invite-players"] = <span>Invita<br />Giocatori</span>;
    resources["cancel-game"] = "Annulla Gioco";
    resources["cancel-game-confirm"] = (
        <div>
            <p>Vuoi annullare questo gioco?</p>
            <p>Tutta le informazioni di questo gioco, come i punteggi dei giocatori, saranno <strong>distrutte</strong> e non saranno ripristinabili.</p>
            <p>La connessione con il gioco sarà interrotta e pertanto i giocatori dovranno ricaricare la pagina del gioco.</p>
        </div>);
    resources["last-response"] = "Ultima Risposta";
    resources["last-time"] = "Ultimo Tempo";
    resources["last-score"] = "Ultimo Punteggio";
    resources["score"] = "Punteggio";
    resources["delete-player"] = "Elimina Giocatore";
    resources["delete-player-confirm"] = (
        <div>
            <p>Vuoi eliminare questo giocatore?</p>
            <p>Il suo punteggio verrà <strong>resettato</strong> e non sarà ripristinabile.</p>
            <p>Il giocatore verrà rimosso dal gioco e per accedere dovrà re-iscriversi.</p>
        </div>);
    resources["reset-scores"] = "Resetta Punteggi";
    resources["reset-scores-confirm"] = (
        <div>
            <p>Vuoi resettare <b>permanentemente</b> i punteggi di tutti i giocatori?</p>
        </div>);
    resources["wave"] = "Saluta";
    resources["players"] = "Giocatori";
    resources["viewer"] = "Visualizzatore";
    resources["viewers"] = "Visualizzatori";

    resources["final-leaderboard-confirmation"] = "Vuoi mostrare la classifica finale?";
    resources["pause-tooltip"] = "Intervallo";
    resources["stop-tooltip"] = "Ferma Gioco";
    resources["play-tooltip"] = "Inizia Gioco";
    resources["forward-tooltip"] = "Avanti";
    resources["skip-tooltip"] = "Salta Avanti";
    resources["show-invite-players-tooltip"] = "Mostra Schermata Iniziale";
    resources["hide-invite-players-tooltip"] = "Nascondi Schermata Iniziale";
    resources["show-teams-tooltip"] = "Mostra Giocatori";
    resources["hide-teams-tooltip"] = "Nascondi Giocatori";
    resources["show-leaderboard-tooltip"] = "Mostra Classifica Generale";
    resources["hide-leaderboard-tooltip"] = "Nascondi Classifica Generale";
    resources["show-final-leaderboard-tooltip"] = "Mostra Classifica Finale";
    resources["hide-final-leaderboard-tooltip"] = "Nascondi Classifica Finale";
    resources["enable-partial-leaderboard-tooltip"] = "Abilita Classifica Parziale";
    resources["disable-partial-leaderboard-tooltip"] = "Disabilita Classifica Parziale";
    resources["enable-category"] = "Abilita Categoria";
    resources["disable-category"] = "Disabilita Categoria";
    resources["partial-leaderboard-on-off"] = <span>Classifica<br />Parz. On/Off</span>;
    resources["play-question-tooltip"] = "Riproduci questa Domanda";
    resources["ticket-id"] = "Id Ticket";
    resources["page-at"] = "La tua pagina è online all'indirizzo";
    resources["wish-change-subdomain"] = "Se desideri cambiarlo, contattaci.";
    resources["game-language"] = "Lingua di Gioco";
    resources["notifications"] = "Notifiche";
    resources["notifications-desc"] = "Manda una notifica a tutti i visualizzatori dell'evento.";
    resources["message"] = "Messaggio";
    resources["only-premium-feature"] = "Questa funzione è disponibile solo per account premium.";
    resources["info"] = "Info";
    resources["success"] = "Successo";
    resources["warning"] = "Avviso";
    resources["error"] = "Errore";
    resources["your-store"] = "La tua organizzazione";
    resources["your-space"] = "Il tuo spazio";
    resources["your-company-logo"] = "Il tuo logo";
    resources["edit-store-information"] = "Modifica la tua organizzazione";
    resources["game-link"] = "Link al gioco";
    resources["play-desc"] = "Link diretto di accesso al gioco.";
    resources["project"] = "Proietta";
    resources["project-desc"] = "Link da proiettare su maxi-schermo.";
    resources["removed-from-playlist"] = "Domanda rimossa dalla playlist";
    resources["removed-from"] = "Domanda rimossa da ";
    resources["all-questions-added"] = "Domande aggiunte alla playlist";
    resources["questions-added-to-playlist"] = " domande aggiunte alla playlist.";
    resources["questions-removed-from-playlist"] = "Domande rimosse dalla playlist.";
    resources["max-playlist-questions"] = "Numero massimo di domande raggiunto.";
    resources["confirm-remove-questions"] = "Sicuro di voler rimuovere le domande selezionate dalla playlist corrente?";
    resources["added-to-playlist"] = "Aggiunta alla playlist";
    resources["moved-to-playlist"] = "Spostata alla playlist";
    resources["already-in-playlist"] = "La domanda è già presente nella playlist";
    resources["socials"] = "Social";
    resources["team-members"] = "Team";
    resources["your-socials"] = "I tuoi social";
    resources["your-intro"] = "Intro";
    resources["your-team"] = "Il tuo team";

    // Play Page
    resources["quizmaster-intro"] = (user) => <>
        <p className='text-center'>Fantastico. <b>Sei già un intrattenitore</b>.</p>
        <p className='text-center'>Visita il tuo spazio <Link to={"/" + user.stores[0].subdomain}>{user.stores[0].name}</Link> per modificare le tue informazioni e condurre giochi di successo.</p>
    </>;
    resources["play-the-quizmaster"] = <>
        <h2>Diventa un "quizmaster"</h2>

        <p>Sii l'intrattenitore e conduci IQ Space per i tuoi amici.</p>
        <p>Crea una "virtual room" e condividi il tuo link di gioco con i tuoi giocatori.</p>
    </>;
    resources["fancy-some-fun"] = "Sei alla ricerca di divertimento stuzzicante? Scatena la festa con IQ Space!";
    resources["join-as-player"] = <>
        <h2>Diventa un giocatore</h2>

        <p>Registrati adesso e tieniti pronto per giocare ad IQ Space.</p>
        <p>Il tuo "quizmaster" ti inviterà a giocare.</p>
    </>;
    resources["already-registered"] = "Sei già registrato.";

    resources["price"] = "Prezzo";
    resources["published"] = "Pubblico";
    resources["free"] = "Gratis";
    resources["cant-delete-default-playlist"] = "Non è possibile cancellare la tua playlist di default.";
    resources["event-locked"] = "Non è possibile cancellare questo evento, in quanto relativo a un gioco avviato o delle partecipazioni acquistate.";
    resources["premium-feature"] = <small>Scopri con un account premium.</small>;
    resources["welcome-back-entertainer"] = <><b>Bentornato!</b> Accedi al tuo spazio per gestire i tuoi giochi.</>;
    resources["ticket-fees"] = "Costi Transazioni";
    resources["ticket-fees-desc"] = (<>
        <p>Vuoi guadagnare dagli eventi che ospiti?</p>
        <p>IQ Space charges a service fee for every paid ticket you sell.</p>
        <p>Se i tuoi ticket saranno gratis, non ci sarà alcuna commissione da pagare.</p>
        <p>Altrimenti, per eventi a pagamento:</p>
        <p><CheckIcon /> Paghi soltanto una transazione bancaria di <b>{Settings["pricing"].perc}% + {Settings["pricing"].fixed} €</b> per i ticket che vendi.</p>
        <p><CheckIcon /> I tuoi giocatori pagheranno una quota di prenotazione di <b>{Settings["pricing"].bookingStartsFrom}</b> € per ogni ticket che acquistano.</p>
        <p>Per ogni evento a pagamento, ospita fino a <b>90 giocatori</b>.</p>
    </>);
    resources["must-setup-stripe"] = "Prima di poter vendere ticket devi impostare le tue informazioni di pagamento per ricevere fondi.";
    resources["event-schedule-auto-start"] = "Avvia automaticamente il gioco?"
    resources["event-must-be-published"] = "Non è possibile schedulare l'avvio automatico di un evento non pubblicato.";
    resources["pro-licence-required"] = "Questa funzionalità è disponibile solo con licenza pro."
    resources["pay"] = "Paga"
    resources["upgrade"] = "Upgrade";
    resources["pay-by-card"] = "Paga con carta."
    resources["select-a-card"] = "Seleziona una carta."
    resources["delete-payment-method-confirm"] = "Vuoi eliminare questo metodo di pagamento?";
    resources["pay-by-new-card"] = "Paga con un'altra carta";
    resources["event-online-at"] = "Evento online alla pagina";
    resources["event-location-type-where"] = "Dove sarà accessibile questo evento?";
    resources["unspecified"] = "Non specificato";
    resources["online"] = "Online";
    resources["on-site"] = "On-site";
    resources["both-online-on-site"] = "Sia online che on-site";
    resources["become-a-quizmaster"] = <>Diventa un quizmaster professionista.<br />Registrati e crea i tuoi eventi online su</>;
}