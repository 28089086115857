import Axios from 'axios';

import { errorLog } from "@uderly/react-uderly-ui/api";
import AppContext from '../app/AppContext'
import Settings from '../app/settings';

const qs = require('querystring');

export default class IqSpace {
    static async Store(subdomain) {
        return await Axios.get(Settings.apiUrl + "/stores/bysubdomain/" + subdomain)
            .catch((error) => {
                console.log('error ' + error);
                errorLog(error);
                return error.response;
            });
    }

    static async Events(storeId, upcoming = 0, daysspan = null) {
        let url = Settings.apiUrl + "/events?store_id=" + storeId;
        if (upcoming != null) url += "&upcoming=" + upcoming;
        if (daysspan != null) url += "&daysspan=" + daysspan;

        return await Axios.get(url)
            .catch((error) => {
                console.log('error ' + error);
                errorLog(error);
                return error.response;
            });
    }

    static async GetStoreEventsToday(storeId) {
        let url = Settings.apiUrl + "/events/today?store_id=" + storeId;

        return await Axios.get(url)
            .catch((error) => {
                console.log('error ' + error);
                errorLog(error);
                return error.response;
            });
    }

    static async Event(id) {
        return await Axios.get(Settings.apiUrl + "/events/" + id)
            .catch((error) => {
                console.log('error ' + error);
                errorLog(error);
                return error.response;
            });
    }

    static async EventStats(id) {
        return await Axios.get(Settings.apiUrl + "/events/" + id + "/stats?page_size=300")
            .catch((error) => {
                console.log('error ' + error);
                errorLog(error);
                return error.response;
            });
    }

    static async AttendEvent(eventId, status_id, attended = null) {
        const request = {
            status_id: status_id,
            attended: attended
        };

        return await Axios.post(Settings.apiUrl + "/events/attendances/" + eventId + "/attend", request)
            .catch((error) => {
                console.log('error ' + error);
                errorLog(error);
                return error.response;
            });
    }

    static async MyTickets(upcoming = 0) {
        return await Axios.get(Settings.apiUrl + "/events/attendances/mytickets?upcoming=" + upcoming)
            .catch((error) => {
                console.log('error ' + error);
                errorLog(error);
                return error.response;
            });
    }

    static async Apply(request) {
        return await Axios.post(Settings.apiUrl + "/stores/apply", request)
            .catch((error) => {
                console.log('error ' + error);
                errorLog(error);
                return error.response;
            });
    }

    static async GenerateFreeTickets(event) {
        const request = {
            voucher: {
                amount_perc: 100,
                event_id: event.id
            },
            quantity: 2
        };

        return await Axios.post(Settings.apiUrl + "/events/" + event.id + "/generatevouchers", request)
            .catch((error) => {
                console.log('error ' + error);
                errorLog(error);
                return error.response;
            });
    }

    static async ApplyVoucher(attendance, code) {
        const request = {
            code: code
        };

        return await Axios.post(Settings.apiUrl + "/events/attendances/" + attendance.id + "/applyvoucher", request)
            .catch((error) => {
                console.log('error ' + error);
                errorLog(error);
                return error.response;
            });
    }

    static async Follow(storeId) {
        return await Axios.post(Settings.apiUrl + "/stores/" + storeId + "/follow")
            .catch((error) => {
                console.log('error ' + error);
                errorLog(error);
                return error.response;
            });
    }

    static async Unfollow(storeId) {
        return await Axios.post(Settings.apiUrl + "/stores/" + storeId + "/unfollow")
            .catch((error) => {
                console.log('error ' + error);
                errorLog(error);
                return error.response;
            });
    }

    static async Following() {
        return await Axios.get(Settings.apiUrl + "/users/following")
            .catch((error) => {
                console.log('error ' + error);
                errorLog(error);
                return error.response;
            });
    }

    static async Playlists(resolveQuestions = true) {
        return await Axios.get(Settings.apiUrl + "/playlists?resolve_questions=" + resolveQuestions)
            .catch((error) => {
                console.log('error ' + error);
                errorLog(error);
                return error.response;
            });
    }

    static async Playlist(id) {
        return await Axios.get(Settings.apiUrl + "/playlists/" + id)
            .catch((error) => {
                console.log('error ' + error);
                errorLog(error);
                return error.response;
            });
    }

    static async ClearPlaylist(id) {
        return await Axios.put(Settings.apiUrl + "/playlists/" + id + "/clear")
            .catch((error) => {
                console.log('error ' + error);
                errorLog(error);
                return error.response;
            });
    }

    static async PopulatePlaylist(id) {
        return await Axios.put(Settings.apiUrl + "/playlists/" + id + "/populate",
            {
                clear_playlist: false,
                language_id: 1,
                image_questions_count: 2,
                questions_count: 10
            }
        ).catch((error) => {
            console.log('error ' + error);
            errorLog(error);
            return error.response;
        });
    }

    static async InjestQuestions(id, json) {
        return await Axios.post(Settings.apiUrl + "/packages/" + id + "/injestquestions", json, {
            headers: {
                // Overwrite Axios's automatically set Content-Type
                'Content-Type': 'application/json'
            }
        }).catch((error) => {
            console.log('error ' + error);
            errorLog(error);
            return error.response;
        });
    }

    static async Package(packageId) {
        return await Axios.get(Settings.apiUrl + "/packages/" + packageId)
            .catch((error) => {
                console.log('error ' + error);
                errorLog(error);
                return error.response;
            });
    }


    static async Question(questionId) {
        return await Axios.get(Settings.apiUrl + "/questions/" + questionId)
            .catch((error) => {
                console.log('error ' + error);
                errorLog(error);
                return error.response;
            });
    }

    static async DeleteQuestion(questionId) {
        return await Axios.delete(Settings.apiUrl + "/questions/" + questionId)
            .catch((error) => {
                console.log('error ' + error);
                errorLog(error);
                return error.response;
            });
    }

    static async RemoveFromPlaylist(playlistId, questionId) {
        return await Axios.put(Settings.apiUrl + "/playlists/" + playlistId + "/remove", { question_id: questionId })
            .catch((error) => {
                console.log('error ' + error);
                errorLog(error);
                return error.response;
            });
    }

    static async AddToPlaylist(playlistId, questionId, moveFromSource = false, sourcePlaylistId = null) {
        return await Axios.put(Settings.apiUrl + "/playlists/" + playlistId + "/add",
            {
                question_id: questionId,
                move_from_source: moveFromSource,
                source_playlist_id: sourcePlaylistId
            })
            .catch((error) => {
                console.log('error ' + error);
                errorLog(error);
                return error.response;
            });
    }

    static async RemoveRangeFromPlaylist(playlistId, questionIds) {
        return await Axios.put(Settings.apiUrl + "/playlists/" + playlistId + "/removerange",
            {
                question_ids: questionIds
            })
            .catch((error) => {
                console.log('error ' + error);
                errorLog(error);
                return error.response;
            });
    }

    static async AddRangeToPlaylist(playlistId, questionIds) {
        return await Axios.put(Settings.apiUrl + "/playlists/" + playlistId + "/addrange",
            {
                question_ids: questionIds
            })
            .catch((error) => {
                console.log('error ' + error);
                errorLog(error);
                return error.response;
            });
    }

    static async AddPackageToPlaylist(playlistId, packageId) {
        return await Axios.put(Settings.apiUrl + "/playlists/" + playlistId + "/addpackage",
            {
                package_id: packageId
            })
            .catch((error) => {
                console.log('error ' + error);
                errorLog(error);
                return error.response;
            });
    }

    static async ReorderPlaylist(playlist_id, questionId0, questionId1) {
        const data = {
            question_id0: questionId0,
            question_id1: questionId1
        };

        return await Axios.put(Settings.apiUrl + "/playlists/" + playlist_id + "/reorder", data)
            .catch((error) => {
                console.log('error ' + error);
                errorLog(error);
                return error.response;
            });
    }

    static async ReorderPackage(package_id, questionId0, questionId1) {
        const data = {
            question_id0: questionId0,
            question_id1: questionId1
        };

        return await Axios.put(Settings.apiUrl + "/packages/" + package_id + "/reorder", data)
            .catch((error) => {
                console.log('error ' + error);
                errorLog(error);
                return error.response;
            });
    }

    static async Packages(onlyEditable = null) {
        let url = AppContext.s["api-url"] + "/packages";
        if (onlyEditable !== null) url += "?onlyEditable=" + onlyEditable;

        return await Axios.get(url)
            .catch((error) => {
                console.log('error ' + error);
                errorLog(error);
                return error.response;
            });
    }

    static async StartGame(eventId, request = {}) {
        const url = Settings.apiUrl + "/squizzer/" + eventId + "/start";

        return await Axios.post(url, request)
            .catch((error) => {
                console.log('error ' + error);
                errorLog(error);
            });
    }

    static async StopGame(eventId) {
        const url = Settings.apiUrl + "/squizzer/" + eventId + "/stop";

        return await Axios.post(url)
            .catch((error) => {
                console.log('error ' + error);
                errorLog(error);
            });
    }

    static async PauseGame(eventId, paused = true) {
        const url = Settings.apiUrl + "/squizzer/" + eventId + "/pause";

        return await Axios.post(url, { paused: paused })
            .catch((error) => {
                console.log('error ' + error);
                errorLog(error);
            });
    }

    static async SubmitStatusChange(eventId, gameStatus) {
        const url = Settings.apiUrl + "/squizzer/" + eventId + "/submitstatuschange";

        return await Axios.post(url, { gameStatus: gameStatus })
            .catch((error) => {
                console.log('error ' + error);
                errorLog(error);
            });
    }

    static async DeleteGame(eventId) {
        const url = Settings.apiUrl + "/squizzer/" + eventId + "/cancel";

        return await Axios.delete(url)
            .catch((error) => {
                console.log('error ' + error);
                errorLog(error);
            });
    }

    static async SetMaxPlayers(eventId, maxPlayers) {
        const url = Settings.apiUrl + "/squizzer/" + eventId + "/setmaxplayers";
        const data = { maxPlayers: maxPlayers };

        return await Axios.post(url, data)
            .catch((error) => {
                console.log('error ' + error);
                errorLog(error);
            });
    }

    static async DeletePlayer(eventId, playerId, all = false) {
        const url = Settings.apiUrl + "/squizzer/" + eventId + "/player/delete";
        const data = {
            playerId: playerId,
            all: all
        };

        return await Axios.post(url, data)
            .catch((error) => {
                console.log('error ' + error);
                errorLog(error);
            });
    }

    static async SetPlayerScore(eventId, playerId, score = 0, all = false) {
        const url = Settings.apiUrl + "/squizzer/" + eventId + "/player/setscore";
        const data = {
            playerId: playerId,
            score: score,
            all: all
        };

        return await Axios.post(url, data)
            .catch((error) => {
                console.log('error ' + error);
                errorLog(error);
            });
    }

    static async WavePlayer(eventId, playerId, message = null) {
        const url = Settings.apiUrl + "/squizzer/" + eventId + "/player/wave";
        const data = {
            playerId: playerId,
            message: message
        };

        return await Axios.post(url, data)
            .catch((error) => {
                console.log('error ' + error);
                errorLog(error);
            });
    }

    static async Notify(eventId, message = null, title = null, severity = "info", duration = 6000, playerId = null) {
        const url = Settings.apiUrl + "/squizzer/" + eventId + "/notify";
        const data = {
            playerId: playerId,
            message: message,
            title: title,
            severity: severity,
            duration: duration,
        };

        return await Axios.post(url, data)
            .catch((error) => {
                console.log('error ' + error);
                errorLog(error);
            });
    }

    static async Forward(eventId, playlistId = -1, abortController = null) {
        const url = Settings.apiUrl + "/squizzer/" + eventId + "/forward";

        const config = {};

        if (abortController)
            config.signal = abortController.signal;

        return await Axios.post(url, {
            playlistId: playlistId
        }, config).catch((error) => {
            console.log('error ' + error);
            errorLog(error);
        });
    }

    static async Play(eventId, questionId, playlistId = -1, abortController = null) {
        const url = Settings.apiUrl + "/squizzer/" + eventId + "/play";

        const config = {};

        if (abortController)
            config.signal = abortController.signal;

        return await Axios.post(url, {
            questionId: questionId,
            playlistId: playlistId
        }, config).catch((error) => {
            console.log('error ' + error);
            errorLog(error);
        });
    }

    static async EnablePartialRanking(eventId, enable = true) {
        const url = Settings.apiUrl + "/squizzer/" + eventId + "/enablepartialranking";
        const data = {
            enable: enable
        };

        return await Axios.post(url, data)
            .catch((error) => {
                console.log('error ' + error);
                errorLog(error);
            });
    }

    static async EnableAutoPilot(eventId, enable = true) {
        const url = Settings.apiUrl + "/squizzer/" + eventId + "/enableautopilot";
        const data = {
            enable: enable
        };

        return await Axios.post(url, data)
            .catch((error) => {
                console.log('error ' + error);
                errorLog(error);
            });
    }

    static async EnableCategory(eventId, enable = true) {
        const url = Settings.apiUrl + "/squizzer/" + eventId + "/enablecategory";
        const data = {
            enable: enable
        };

        return await Axios.post(url, data)
            .catch((error) => {
                console.log('error ' + error);
                errorLog(error);
            });
    }

    static async StoreCategories(filters = null) {
        let url = Settings.apiUrl + "/categories";

        if (filters)
            url += "/?" + qs.stringify(filters);

        return await Axios.get(url)
            .catch((error) => {
                console.log('error ' + error);
                errorLog(error);
            });
    }

    static async AddCollection(package_id, store_id) {
        const url = Settings.apiUrl + "/collections/" + package_id + "/addcollection";

        return await Axios.put(url, { store_id: store_id })
            .catch((error) => {
                console.log('error ' + error);
                errorLog(error);
            });
    }

    static async RemoveCollection(package_id, store_id) {
        const url = Settings.apiUrl + "/collections/" + package_id + "/removecollection";

        return await Axios.put(url, { store_id: store_id })
            .catch((error) => {
                console.log('error ' + error);
                errorLog(error);
            });
    }

    static async Colors() {
        return await Axios.get(Settings.apiUrl + "/colors")
            .catch((error) => {
                console.log('error ' + error);
                errorLog(error);
                return error.response;
            });
    }
}