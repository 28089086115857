import React from "react";

import AppContext from "app/AppContext";

export default class Socials extends React.Component {
    render() {
        return (
            <div className="socials-list">
                <a href={AppContext.s['provider'].facebookPageUrl} target="_blank" rel="noreferrer">
                    <img alt="Facebook" src="/assets/images/facebook-icon-32.png" />
                </a>

                <a href={AppContext.s['provider'].linkedinPageUrl} target="_blank" rel="noreferrer">
                    <img alt="Facebook" src="/assets/images/linkedin-icon-32.png" />
                </a>
            </div>
        );
    }
}