import React from 'react';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import GetAppIcon from '@mui/icons-material/GetApp';
import {
    FacebookShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton
} from "react-share";

import {
    FacebookIcon,
    TelegramIcon,
    TwitterIcon,
    WhatsappIcon
} from "react-share";

import AppContext from '../app/AppContext';
import Ranking from './Ranking'
import RankingItem from './RankingItem'
import Socials from 'framework/Socials';
import { ViewModeStatesEnum } from './Game';
import Settings from '../app/settings';

export class GameCompleted extends React.Component {
    state = {
        showLeaderboard: false
    };

    render() {
        const { event, viewMode } = this.props;

        if (!event)
            return "";

        const gs = this.props.gameStatus;

        let i = 0;
        const miniLeaderboard = [].concat(this.props.gameStatus.players.slice(0, 3))
            .sort((a, b) => a.Score < b.Score ? -1 : 1)
            // .filter(x => x.Enabled)
            .map((player) =>
                <RankingItem position={++i} key={player.id} player={player} showMedal={true} showScore={true} isPartial={false}
                    you={(gs.myPlayer && player.id === gs.myPlayer.id) ? true : false} />
            );

        const imageUrl = Settings.apiUrl + "/" + event.id + "/getimage?title=" + encodeURIComponent(event.title);

        const shareLeaderboard = (event && viewMode === ViewModeStatesEnum.Mobile) ?
            <>
                <h3 className="small-uppercase-title">{AppContext.r["share-leaderboard"]}</h3>

                <div className="share-leaderboard">
                    <FacebookShareButton url={imageUrl} title={event.title}
                        hashtag="#iqspace">
                        <FacebookIcon />
                    </FacebookShareButton>

                    {/* <FacebookMessengerShareButton url={imageUrl} title={event.title} 
                        appId={AppContext.s["facebook-app-id"]} redirectUri={window.location.href}>
                        <FacebookMessengerIcon />
                    </FacebookMessengerShareButton> */}

                    <WhatsappShareButton url={imageUrl} title={event.title} >
                        <WhatsappIcon />
                    </WhatsappShareButton>

                    <TelegramShareButton url={imageUrl} title={event.title}>
                        <TelegramIcon />
                    </TelegramShareButton>

                    <TwitterShareButton url={imageUrl} title={event.title}>
                        <TwitterIcon />
                    </TwitterShareButton>

                    <IconButton className="download-leaderboard"
                        href={imageUrl + "&content-type=attachment"} target="_blank" rel="noopener noreferrer" aria-label="download">
                        <GetAppIcon />
                    </IconButton>
                </div>
            </> : "";

        return (
            <>
                <div className="game-completed">
                    <h1>{AppContext.r['game-completed']}</h1>
                    {/* <p className="new-game-starting-soon">{AppContext.r['new-game-starting-soon']}</p> */}
                    <img className="trophy-img" src={AppContext.s['trophy']} alt="Trophy" />
                    <h2>{AppContext.r['congrats-winners']}</h2>

                    <div className="mini-leaderboard">
                        {miniLeaderboard}
                    </div>

                    <Button variant="outlined" className="show-leaderboard"
                        onClick={() => this.setState({ showLeaderboard: true })}>{AppContext.r['show-leaderboard']}</Button>

                    <div className="like-this-game">
                        {AppContext.r['like-this-game']}
                        <Socials />
                    </div>
                    {/* { shareLeaderboard } */}
                </div>

                {this.state.showLeaderboard &&
                    <>
                        <Ranking gameStatus={gs} players={gs.players} colsPerPage={this.props.colsPerPage} />
                        <Button variant="outlined" className="hide-leaderboard"
                            onClick={() => this.setState({ showLeaderboard: false })}
                            endIcon={<CloseIcon />}>{AppContext.r['close']}</Button>
                    </>}
            </>
        );
    }
}
