import React from "react";
import { Field } from 'formik';
import { TextField, Switch as FormikSwitch } from 'formik-mui';
import { connect } from 'react-redux';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import { formatInTimeZone } from 'date-fns-tz';
import * as yup from 'yup';

import { updateItemRequest, getLanguages } from '@uderly/react-uderly-ui/api';
import AppContext from 'app/AppContext';
import CoreApi from "api/Core";
import IqSpaceApi from 'api/IqSpace';
import Module from 'framework/Module';
import RemoteSelect from 'framework/RemoteSelect';
import { withModal } from 'framework/withModal';
import { setAlert } from '../../redux/layoutSlice';
import MediaStockImage from '../../framework/MediaStockImage';
import PlaylistQuestions from '../components/PlaylistQuestions';
import Separator from "app/components/Separator";
import Settings from '../../app/settings';

class Packages extends React.Component {
    state = {
        store: this.props.store
    };

    apiPath = "/packages";

    get schema() {
        const v = {
            name: yup.string().required(AppContext.r["required-field"])
        };

        return yup.object(v);
    }

    get tableHead() {
        return [
            {
                Title: AppContext.r["name"],
                Adapter: (o) => {
                    return (
                        <div className="td-v-center">
                            <div className="row-thumb">
                                {(o.media && o.media[0]) && <>
                                    <img src={((o.media[0].disk === "net") ? Settings.apiUrl : AppContext.s["api-url"]) + this.apiPath + "/" + o.id + "/image/thumb"} alt="Thumb" />
                                </>}
                                {(!o.media || !o.media[0]) && <img src={AppContext.s["placeholder"]} className="placeholder-thumb" alt="Thumb" />}
                            </div>

                            {o.name}
                        </div>);
                }
            },
            {
                Title: AppContext.r["questions-count"],
                Adapter: (o) => <div className="td-v-center">{o.questions_count}</div>
            }
        ];
    }

    async componentDidMount() {
        if (this.props.updateLayout)
            this.props.updateLayout();

        const languagesResponse = await getLanguages();

        this.languageOptions = CoreApi.mapData(languagesResponse.data.data, "id");

        const response = await IqSpaceApi.Store(this.props.entertainer);

        if (response && response.status === 200) {
            const store = response.data.data;

            this.initialValues = {
                id: null,
                name: "",
                language_id: store.language_id,
                published: false
            };

            this.setState({
                store: store
            });
        }
    }

    onInsertItem = (item) => {
        const s = {
            item: item
        };

        this.setState(s);
    }

    onFetchedItem = (item) => {
        const s = {
            item: item
        };

        this.setState(s);
    }

    onImageFileChanged = async (imageFile) => {
        const { item } = this.state;

        const m = [
            {
                name: imageFile.name,
                size: imageFile.size,
                mime_type: imageFile.type
            }
        ];

        this.setState({
            media: m
        });

        if (item.id)
            await updateItemRequest(Settings.apiUrl + this.apiPath + "/" + item.id, { ...item, media: m });
    }

    get imageUrl() {
        return Settings.apiUrl + this.apiPath + "/" + this.state.item.id + "/image";
    }

    view = ({ values, setFieldValue }) => {
        const { store } = this.state;

        if (values["published"] === 1)
            values["published"] = true;

        return (
            <>
                <Grid container>
                    <Grid container item spacing={1} sm md lg xl>
                        <Grid item xs={12} xl={12}>
                            <Field component={TextField} name="name" type="text"
                                label={AppContext.r["name"] + "*"} />

                            {/* <Field component={TextField} name="name" type="text" 
                                label={AppContext.r["name"] + "*"} error={touched.name && Boolean(errors.name)} /> */}
                        </Grid>

                        {values.created_at &&
                            <Grid item sm={4} lg={3}>
                                <div className="outlined-with-label disabled">
                                    {formatInTimeZone(values.created_at, values.timezone, "dd/MM/yyyy H:mm:ss")}
                                    <label>{AppContext.r["created-at"]}</label>
                                </div>
                            </Grid>}

                        <Grid item xs={6} sm={4} lg={3} marginRight={1}>
                            <RemoteSelect name="language_id" key={"language-" + values.language_id} value={values.language_id}
                                onChange={(e, value) => {
                                    setFieldValue("language_id", value);
                                }}
                                nullOption={false} fieldToMap="name" options={this.languageOptions} label={AppContext.r["language"]} />
                        </Grid>

                        <Grid item sm={2} lg={1}>
                            <FormControlLabel control={<Field component={FormikSwitch} type="checkbox" name="published" checked={values.published} />}
                                label={AppContext.r["published"]} onChange={(e, checked) => setFieldValue("published", checked)} />
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} className="image-col small">
                        <MediaStockImage sx={{ width: 56, height: 56 }}
                            apiPath={this.apiPath}
                            imageUrl={values.id ? this.imageUrl : null}
                            item={values}
                            onImageFileChanged={this.onImageFileChanged} />
                    </Grid>
                </Grid>

                <div className="questions-view">
                    {(values && values.questions) &&
                        <>
                            <h3 className="text-center">{values.questions_count} {AppContext.r["questions"]}</h3>
                            <Separator />

                            <PlaylistQuestions store={store} playlist={values} isPackage packageId={values.id}
                                onQuestionsChanged={p => setFieldValue("questions_count", p.length)}
                                onQuestionRemoved={q => setFieldValue("questions_count", values.questions_count - 1)}
                                onQuestionInserted={q => setFieldValue("questions_count", values.questions_count + 1)} />
                        </>}
                </div>
            </>);
    }

    updateDataAdapter = (o) => {
        o["media"] = null;

        return o;
    }

    insertDataAdapter = (o) => {
        o["media"] = this.state.media;
        o["store_id"] = this.state.store.id;

        return o;
    }

    render() {
        const { store } = this.state;

        return (
            <div className="page module playlists">
                {store &&
                    <Module modal={this.props.modal} setAlert={this.props.setAlert}
                        schema={this.schema} insertDataAdapter={this.insertDataAdapter}
                        path={"/packages"}
                        apiUrl={Settings.apiUrl}
                        updateDataAdapter={this.updateDataAdapter}
                        apiPath={this.apiPath}
                        accessApiPath={this.apiPath + "?store_id=" + store.id}
                        showApiPath={this.apiPath}
                        store={store} id={this.props.id} tableHead={this.tableHead}
                        view={this.view} viewClassName="playlist-mask mask"
                        onInsertItem={this.onInsertItem}
                        onFetchedItem={this.onFetchedItem}
                        initialValues={this.initialValues}
                        authActions={this.props.authActions}
                        enablePublishedQuickToggle />}
            </div>
        );
    }
}

const mapDispatchToProps = () => ({
    setAlert
});

export default connect(null, mapDispatchToProps())(withModal(Packages));