import React, { createRef } from 'react';
import { Button, Tabs, Tab } from 'react-bootstrap';
import { arrayRemove } from 'react-movable';

import { createItemRequest } from '@uderly/react-uderly-ui/api';
import AppContext from 'app/AppContext';
import IqSpaceApi from 'api/IqSpace';
import GameStatusEnum from 'api/QuizStatusEnum.js';
import RemoteSelect from 'framework/RemoteSelect';
import JsonToTable from 'framework/JsonToTable';
import ActionButton from 'framework/ActionButton';
import Countdown from 'framework/Countdown';
import { withModal } from 'framework/withModal.js';
import PlaylistQuestions from './PlaylistQuestions';
import { indexToLetter } from './PlaylistQuestions';
import ShareEvent from './ShareEvent.js';
import Separator from "./Separator";
import MainControls from "./QuizPanel/MainControls";
import GameOptionsControls from "./QuizPanel/GameOptionsControls";
import Settings from '../../app/settings';
import { hasPermission } from '../utils';
import withUderlyStore from "../../zustand/withUderlyStore";

const signalR = require("@microsoft/signalr");

class QuizConsole extends React.Component {
    connection = React.createRef();

    state = {
        event: this.props.event,
        gameStatus: null,
        playlists: null,
        playlist: null,
        playlistId: null
    };

    jPlayersHead = [
        {
            Title: AppContext.r["name"],
            Field: "name"
        },
        {
            Title: AppContext.r["last-response"],
            Adapter: (o) => <><b>{indexToLetter(o.lastAnswer)}</b> {o.lastAnswer >= 0 && <> ({o.lastTime}ms)</>} </>
        },
        {
            Title: AppContext.r["last-score"],
            Field: "lastScore"
        },
        {
            Title: AppContext.r["score"],
            Field: "score"
        },
        {
            Adapter: (o, index) => {
                return (
                    <>
                        {/* <Button variant="link" className="warning" onClick={() => { t.wavePlayer(o, index) }}>
                            <i className="fas fa-hand-paper"></i> {AppContext.r['wave']}
                        </Button> */}
                        <Button variant="link" className="danger" onClick={() => { this.confirmDeletePlayer(o, index) }}>
                            <i className="fas fa-trash-alt"></i> {AppContext.r['delete']}
                        </Button>
                    </>);
            }
        }
    ];

    start = async () => {
        if (this.connection.current.state === signalR.HubConnectionState.Disconnected) {
            try {
                await this.connection.current.start();
                console.assert(this.connection.current.state === signalR.HubConnectionState.Connected);
                // console.log("SignalR Connected.", this.connection.current);

                this.onConnected();
            } catch (err) {
                console.assert(this.connection.current.state === signalR.HubConnectionState.Disconnected);
                console.error(err);

                setTimeout(async () => await this.start(), 1000);
            }
        }
    }

    startConnection = async () => {
        if (!this.connection.current) {
            const hubUrl = Settings.apiUrl + "/iqhub";

            const connection = new signalR.HubConnectionBuilder()
                .withUrl(hubUrl)
                .withAutomaticReconnect()
                .build();

            connection.onreconnecting(error => {
                console.assert(connection.state === signalR.HubConnectionState.Reconnecting);
                this.onDisconnected();
            });

            connection.onreconnected(connectionId => {
                console.assert(connection.state === signalR.HubConnectionState.Connected);
                this.onConnected();
            });

            connection.onclose(error => {
                console.assert(connection.state === signalR.HubConnectionState.Connected);
                console.log(error);

                this.onDisconnected();
            });

            connection.on("gameStatus", (gs) => {
                this.onGameStatusChanged(gs);
            });

            this.connection.current = connection;
        }

        if (this.connection.current.state === signalR.HubConnectionState.Disconnected)
            await this.start();
    }

    fetchPlaylists = () => {

    }

    async componentDidMount() {
        // Fetch user's playlist
        const playlistsResponse = await IqSpaceApi.Playlists(false);

        if (playlistsResponse) {
            if (playlistsResponse.data.data)
                this.setState({
                    playlists: playlistsResponse.data.data,
                    playlistId: playlistsResponse.data.data[0] ? playlistsResponse.data.data[0].id : -1
                });

            // Connect to the hub
            if (!this.connection.current)
                this.startConnection();
        }

        window.addEventListener("focus", this.onFocus);
    }

    componentWillUnmount() {
        window.removeEventListener("focus", this.onFocus);
    }

    onPlaylistChanged = async (e, playlistId) => {
        if (playlistId) {
            const { gameStatus } = this.state;

            const playingQuestionId = (!gameStatus || !gameStatus.question) ? null : gameStatus.question.QuestionId;

            await this.fetchPlaylist(playlistId, playingQuestionId);
        }
    }

    fetchPlaylist = async (playlistId, selectedQuestionId = null) => {
        if (!playlistId && this.state.playlists) {
            // const notDefaultPlaylists = this.state.playlists.filter(p => !p.is_default);
            // playlistId = notDefaultPlaylists.length > 0 ? this.state.playlists[0].id : null;

            if (this.state.playlists.length > 0)
                playlistId = this.state.playlists[0].id;
        }

        const response = await IqSpaceApi.Playlist(playlistId);

        if (response && response.data) {
            const playlist = response.data.data;

            // Highlight playing question
            if (playlist && playlist.questions)
                for (var q of playlist.questions) {
                    q["selected"] = false;

                    if (selectedQuestionId && q.id === selectedQuestionId) {
                        q["selected"] = true;
                    }
                }

            this.setState({
                playlist: playlist,
                playlistId: playlistId
            });
        }

        return response;
    }

    onSubscribed = async (result) => {
        const { playlists } = this.state;

        const messages = [];

        // Retrieve sent chat messages
        if (result["ChatMessages"]) {
            for (var m of result["ChatMessages"]) {
                if (m.ToPlayerId === -1)
                    messages.push({
                        id: m.PlayerId,
                        name: m.Name,
                        message: m.Message,
                        userId: m.UserId
                    });
            }
        }

        if (result && !result.User && this.state.player)
            this.quitGame();

        const playingPlaylistId = (!result.gameStatus || !result.gameStatus.playlistId) ? ((playlists && playlists.length > 0) ? playlists[0].id : null) : result.gameStatus.playlistId;
        const playingQuestionId = (!result.gameStatus || !result.gameStatus.question) ? null : result.gameStatus.question.questionId;

        const response = !playingPlaylistId ? null : await this.fetchPlaylist(playingPlaylistId, playingQuestionId);

        // Subscribed to the hub
        this.onGameStatusChanged(result.gameStatus, response && response.data && response.data.data ? response.data.data : null);

        this.props.overlay.setView("", false);
        this.props.overlay.hide();

        this.setState({
            subscribed: true,
            player: (result.gameStatus && result.gameStatus.myPlayer) ? result.gameStatus.myPlayer : null,
            broadcastChatMessages: messages,
            gameStatus: result.gameStatus
        });

        // Scroll chat to the bottom
        const el = document.getElementById("chat");
        if (el)
            el.scrollTop = el.scrollHeight;
    }

    wavePlayer = async (o/*, index*/) => {
        const { event } = this.state;

        const response = await IqSpaceApi.WavePlayer(event.id, o.Id);

        if (response && response.status === 200) { }

        this.props.modal.hide();
    }

    notify = async (values) => {
        const { event } = this.state;

        const response = await IqSpaceApi.Notify(event.id, values.message, values.title, values.severity, values.duration);

        if (response && response.status === 200) { }

        this.props.modal.hide();
    }

    confirmResetScores = () => {
        this.props.modal.confirm(AppContext.r["reset-scores"], AppContext.r["reset-scores-confirm"], this.resetScores);
    }

    confirmDeletePlayer = (o/*, index*/) => {
        this.props.modal.confirm(AppContext.r["delete-player"], AppContext.r["delete-player-confirm"], () => this.deletePlayer(o));
    }

    resetScores = async () => {
        const { event } = this.state;

        const response = await IqSpaceApi.SetPlayerScore(event.id, -1, 0, true);

        if (response && response.status === 200) { }

        this.props.modal.hide();
    }

    deletePlayer = async (o) => {
        const { event } = this.state;

        const response = await IqSpaceApi.DeletePlayer(event.id, o.id);

        if (response && response.status === 200) { }

        this.props.modal.hide();
    }

    getSafeConnection = async () => {
        const state = this.connection.current ? this.connection.current.state : null;

        while (!this.connection.current || state === signalR.HubConnectionState.Disconnected
            || state === signalR.HubConnectionState.Disconnecting) {
            await this.startConnection();
        }

        return this.connection.current;
    }

    onFocus = () => {
        // console.log("FOCUS", this.connection.current.state);

        // if (this.connection.current.state !== signalR.HubConnectionState.Connected) {
        //     alert("MUST CONNECT")
        // }

        //  if (this.eventId && this.cachedPlayer && this.state.user
        //         && window.squizzerHub.State >= 1)
        //     window.squizzerHub.subscribe(this.eventId, this.cachedPlayer, this.state.user.id, true);
    }

    hub_subscribe = async (eventId, player, userId, asDirector = false) => {
        // console.log("Attempt to subscribe" + (asDirector ? " as a director" : ""));

        try {
            let result = null;

            if (asDirector) {
                // Subscribe as a director
                result = await (await this.getSafeConnection()).invoke('subscribeDirector', parseInt(eventId));
                console.log("Subscribed as a director", result)
            } else if (player && userId) {
                // Subscribe as a player
                const request = {
                    eventId: parseInt(eventId),
                    userId: userId,
                    name: player.Name
                };

                result = await this.getSafeConnection().invoke('subscribePlayer', request);
                console.log("Subscribed as a player", result);
            } else {
                // Subscribe as a viewer
                result = await this.getSafeConnection().invoke('subscribeViewer', parseInt(eventId));
            }

            this.onSubscribed(result);
        } catch (error) {
            this.onSubscriptionFailed(error);
        }
    }

    onConnecting() {
        this.props.overlay.setView(AppContext.r['rts-connecting'], true);
    }

    onConnected = async () => {
        this.props.overlay.setView(AppContext.r['rts-connected'], true);

        const { event } = this.state;

        await this.hub_subscribe(event.id, null, null, true);
    }

    onDisconnected = async () => {
        this.props.overlay.setView(AppContext.r['rts-disconnected'], true);

        await this.start();
    }

    onConnectionToHubError() {
        this.props.overlay.setView(AppContext.r['rts-couldnt-connect'], true);
    }

    onSubscriptionFailed(error) {
        this.props.overlay.setView(AppContext.r['rts-subscription-failed'] + JSON.stringify(error), true);
    }

    onGameStatusChanged(gameStatus, playlist = null) {
        if (!playlist) playlist = this.state.playlist;

        let hPlaylist = null;

        if (gameStatus && gameStatus.question && playlist) {
            hPlaylist = { ...playlist };

            for (var q of hPlaylist.questions) {
                q["selected"] = false;

                if (q.id === gameStatus.question.questionId) {
                    q["selected"] = true;
                }
            }
        }

        this.setState({
            gameStatus: gameStatus,
            playlist: hPlaylist ? hPlaylist : playlist
        });
    }

    deleteGame = async () => {
        const { event } = this.state;

        const response = await IqSpaceApi.DeleteGame(event.id);

        if (response && response.status === 200) {
            this.setState({
                gameStatus: null
            });

            await this.hub_subscribe(event.id, null, null, true);
        }

        if (this.props.onGameDeleted)
            this.props.onGameDeleted();

        this.props.modal.hide();
    }

    confirmDeleteGame = () => {
        this.props.modal.confirm(AppContext.r["cancel-game"], AppContext.r["cancel-game-confirm"], this.deleteGame);
    }

    abortController = createRef(null);

    forward = async (eventId) => {
        const { playlistId } = this.state;

        const response = await IqSpaceApi.Forward(eventId, playlistId);

        if (response && response.status === 200)
            this.setState({
                gameStatus: { ...this.state.gameStatus, question: null }
            });
    }

    onRemovedFromPlaylist = (o, index) => {
        const playlist = this.state.playlist;
        playlist.questions = arrayRemove(playlist.questions, index);

        this.setState({
            playlist: playlist
        });
    }

    onReordered = (playlist) => {
        // console.log(playlist);
    }

    quickCreatePlaylist = () => {
        this.props.modal.input(AppContext.r["create-playlist"], "",
            async (t) => {
                const response = await createItemRequest(AppContext.s["api-url"] + "/playlists", { name: t });

                if (response && response.status === 201) {
                    const playlist = (response.data.data);

                    // TODO: IS IT WORKING?

                    await this.fetchPlaylists();

                    playlist["questions"] = [];

                    this.setState({
                        playlist: playlist,
                        playlistId: playlist.id,
                    });
                }

                this.props.modal.hide();
            });
    }

    get console() {
        const { gameStatus, event } = this.state;

        const notificationsTab = ({/*<Tab eventKey="notify" title={AppContext.r["notifications"]}>
            <div className="notifications-tab">
                <Row>
                    <Col md={12}>
                        <h3>{AppContext.r["notifications-desc"]}</h3>

                        {!isPremium &&
                            <p><small>{AppContext.r["only-premium-feature"]}</small></p>}
                    </Col>
                </Row>

                <Formik
                    initialValues={{ message: "", title: "", severity: "info", duration: 5000 }}
                    validationSchema={yup.object({ message: yup.string().required(AppContext.r["required-field"]) })}
                    onSubmit={(values) => this.notify(values)}>

                    {({ isSubmitting, values }) => (
                        <Form className={"notifications-form " + (isPremium ? "" : "disabled-form")}>
                            <Row>
                                <Col md={6}>
                                    <Field className="field" component={TextField} type="text" variant="standard" margin="normal"
                                        value={values.title} name="title" label={AppContext.r["title"]} />
                                </Col>

                                <Col md={3}>
                                    <Field className="field" component={TextField} type="text" select variant="standard" margin="normal"
                                        InputLabelProps={{ shrink: true }}
                                        value={values.severity} name="severity" label={AppContext.r["type"]}>
                                        <MenuItem value={"info"}>{AppContext.r["info"]}</MenuItem>
                                        <MenuItem value={"success"}>{AppContext.r["success"]}</MenuItem>
                                        <MenuItem value={"warning"}>{AppContext.r["warning"]}</MenuItem>
                                        <MenuItem value={"error"}>{AppContext.r["error"]}</MenuItem>
                                    </Field>
                                </Col>

                                <Col md={3}>
                                    <Field className="field" component={TextField} type="text" select variant="standard" margin="normal"
                                        InputLabelProps={{ shrink: true }}
                                        value={values.duration} name="duration" label={AppContext.r["duration"]}>
                                        <MenuItem value={3000}>3s</MenuItem>
                                        <MenuItem value={5000}>5s</MenuItem>
                                        <MenuItem value={8000}>8s</MenuItem>
                                        <MenuItem value={10000}>10s</MenuItem>
                                        <MenuItem value={15000}>15s</MenuItem>
                                        <MenuItem value={20000}>20s</MenuItem>
                                    </Field>
                                </Col>
                            </Row>

                            <Row className="justify-content-center">
                                <Col md={12}>
                                    <Field className="field" component={TextField} type="text" multiline variant="standard" margin="normal"
                                        value={values.message} name="message" label={AppContext.r["message"]} />
                                </Col>
                            </Row>

                            <Row className="justify-content-center">
                                <MuiButton type="submit" variant="outlined" disabled={isSubmitting}>{AppContext.r["submit"]}</MuiButton>
                            </Row>

                            {isSubmitting && <LinearProgress />}
                        </Form>
                    )}
                </Formik>
            </div>
        </Tab>*/});


        const rankingControlsDisabled = !(gameStatus &&
            (gameStatus.gameStatus === GameStatusEnum.Started || gameStatus.gameStatus === GameStatusEnum.InvitePlayers
                || gameStatus.gameStatus > GameStatusEnum.AnswersTimerOn));

        const invitePlayersControlView = (gameStatus && !gameStatus.completed) ? (
            <>
                {/* <label>{AppContext.r["invite-players"]}</label> */}

                {(gameStatus.gameStatus === GameStatusEnum.InvitePlayers)
                    && <ActionButton className="invite-players" selected icon="fas fa-bolt"
                        tooltip={AppContext.r["hide-invite-players-tooltip"]} disabled={rankingControlsDisabled}
                        action={async () => await IqSpaceApi.SubmitStatusChange(event.id, GameStatusEnum.CorrectAnswer)} />}
                {(gameStatus.gameStatus !== GameStatusEnum.InvitePlayers)
                    && <ActionButton className="invite-players" icon="fas fa-bolt"
                        tooltip={AppContext.r["show-invite-players-tooltip"]} disabled={rankingControlsDisabled}
                        action={async () => {
                            await IqSpaceApi.SubmitStatusChange(event.id, GameStatusEnum.CorrectAnswer);
                            await IqSpaceApi.SubmitStatusChange(event.id, GameStatusEnum.InvitePlayers);
                        }} />}
            </>
        ) : "";

        const rankingControls = (<>
            {invitePlayersControlView}

            {(gameStatus && !gameStatus.completed)
                && <div className="controls-separator"></div>}

            {(gameStatus && !gameStatus.completed && gameStatus.gameStatus === GameStatusEnum.Teams)
                && <ActionButton className="teams" selected icon="fas fa-list-ul"
                    tooltip={AppContext.r["hide-teams-tooltip"]} disabled={rankingControlsDisabled}
                    action={async () => await IqSpaceApi.SubmitStatusChange(event.id, GameStatusEnum.CorrectAnswer)} />}
            {(gameStatus && !gameStatus.completed && gameStatus.gameStatus !== GameStatusEnum.Teams)
                && <ActionButton className="teams" icon="fas fa-list-ul"
                    tooltip={AppContext.r["show-teams-tooltip"]} disabled={rankingControlsDisabled}
                    action={async () => {
                        await IqSpaceApi.SubmitStatusChange(event.id, GameStatusEnum.CorrectAnswer);
                        await IqSpaceApi.SubmitStatusChange(event.id, GameStatusEnum.Teams);
                    }} />}

            {(gameStatus && !gameStatus.completed && gameStatus.gameStatus === GameStatusEnum.Ranking)
                && <ActionButton className="leaderboard" selected icon="fas fa-list-ol"
                    tooltip={AppContext.r["hide-leaderboard-tooltip"]} disabled={rankingControlsDisabled}
                    action={async () => await IqSpaceApi.SubmitStatusChange(event.id, GameStatusEnum.CorrectAnswer)} />}
            {(gameStatus && !gameStatus.completed && gameStatus.gameStatus !== GameStatusEnum.Ranking)
                && <ActionButton className="leaderboard" icon="fas fa-list-ol"
                    tooltip={AppContext.r["show-leaderboard-tooltip"]} disabled={rankingControlsDisabled}
                    action={async () => {
                        await IqSpaceApi.SubmitStatusChange(event.id, GameStatusEnum.CorrectAnswer);
                        await IqSpaceApi.SubmitStatusChange(event.id, GameStatusEnum.Ranking);
                    }} />}

            {(gameStatus && !gameStatus.completed && gameStatus.gameStatus === GameStatusEnum.FinalRanking)
                && <ActionButton className="final leaderboard" selected icon="fas fa-medal"
                    tooltip={AppContext.r["hide-final-leaderboard-tooltip"]} disabled={rankingControlsDisabled}
                    action={async () => await IqSpaceApi.SubmitStatusChange(event.id, GameStatusEnum.CorrectAnswer)} />}
            {(gameStatus && !gameStatus.completed && gameStatus.gameStatus !== GameStatusEnum.FinalRanking)
                && <ActionButton className="final leaderboard" icon="fas fa-medal"
                    tooltip={AppContext.r["show-final-leaderboard-tooltip"]} disabled={rankingControlsDisabled}
                    action={async () => {
                        await IqSpaceApi.SubmitStatusChange(event.id, GameStatusEnum.CorrectAnswer);
                        await IqSpaceApi.SubmitStatusChange(event.id, GameStatusEnum.FinalRanking);
                    }} />}
        </>);

        const { user } = this.props.zustand;

        return (
            <div className="console">
                <div className="cancel-game">
                    {(gameStatus && gameStatus.canCancel && gameStatus.timespanToCancel > 0) &&
                        <>
                            <span className="cancel-by">{AppContext.r["cancel-by"]}</span>
                            <Countdown timespan={gameStatus.timespanToCancel} minutesLabel="m" secondsLabel="s" />
                            <ActionButton className="danger"
                                action={this.confirmDeleteGame} icon="fas fa-trash-alt" />
                        </>}
                </div>

                {hasPermission(user, "game_automation") &&
                    <div className="auto-pilot">
                        {(gameStatus && !gameStatus.completed && gameStatus.enableAutoPilot)
                            && <ActionButton selected icon="fas fa-robot"
                                tooltip={"Disable Auto Pilot"}
                                action={async () => await IqSpaceApi.EnableAutoPilot(event.id, false)} />}

                        {(gameStatus && !gameStatus.completed && !gameStatus.enableAutoPilot)
                            && <ActionButton icon="fas fa-robot"
                                tooltip={"Enable Auto Pilot"}
                                action={async () => {
                                    await IqSpaceApi.EnableAutoPilot(event.id, true);
                                }} />}
                    </div>}

                {(!gameStatus || !gameStatus.expired) &&
                    <>
                        <MainControls event={event} gameStatus={gameStatus} onForward={this.forward} />

                        {gameStatus &&
                            <div className="ranking-controls">
                                {rankingControls}

                                {(gameStatus && !gameStatus.completed && !gameStatus.expired)
                                    && <GameOptionsControls event={event} gameStatus={gameStatus} />}
                            </div>}
                    </>}
            </div>
        );
    }

    get IsNotStarted() {
        return (!this.state.gameStatus || this.state.gameStatus.completed);
    }

    render() {
        const { event, gameStatus, playlists, playlist, playlistId } = this.state;

        if (event === null) {
            return AppContext.r["preloader"];
        } else if (event) {
            return (
                <>
                    <h2 className="text-center">{event.title}</h2>
                    <Separator className="tablet-hidden" />

                    <div className="framed-center">
                        <ShareEvent event={event} rightLabel={AppContext.r["game-link"]} />
                    </div>

                    {this.IsNotStarted &&
                        <>
                            {(event.countdown > 0 && (!gameStatus || !gameStatus.completed)) &&
                                <div className="countdown-to-start">
                                    <Countdown timespan={event.countdown} label={<p>{AppContext.r["event-planned"]}</p>} />
                                </div>}

                            {!gameStatus &&
                                <p className="start-when">{AppContext.r["start-when"]}</p>}
                        </>
                    }

                    {this.console}

                    {/* {gameStatus && <span className="total-viewers">{gameStatus.totalViewers} {AppContext.r["viewers"]}</span>} */}
                    {(gameStatus && gameStatus.expired) && <p className="game-expired">{AppContext.r["game-expired"]}</p>}

                    {(gameStatus && !gameStatus.completed && !gameStatus.expired)
                        && <div className="tabs-container">
                            <Tabs defaultActiveKey="playlists">
                                <Tab eventKey="playlists" title={AppContext.r["playlists"]}>
                                    <>
                                        {(playlists && playlists.length > 0 && playlistId) ?
                                            <RemoteSelect name="playlist_id" className="playlists-select"
                                                label={AppContext.r["select-playlist"]}
                                                nullOption={false} options={playlists}
                                                value={playlistId} onChange={this.onPlaylistChanged}
                                                variant={"standard"} />
                                            : <div className="no-playlists">
                                                <p>{AppContext.r["no-playlists"]}</p>
                                                <Button variant="link" onClick={this.quickCreatePlaylist}>{AppContext.r["create-playlist"]}</Button>
                                            </div>}

                                        {(gameStatus && playlist && playlist.questions) &&
                                            <PlaylistQuestions store={event.store} playlist={playlist} allowPlayback eventId={event.id} enableWatchPlaylist
                                                onRemovedFromPlaylist={this.onRemovedFromPlaylist} onReordered={this.onReordered}
                                                onPlayQuestion={this.onPlayQuestion} />}
                                    </>
                                </Tab>

                                <Tab eventKey="players" title={AppContext.r["players"]}>
                                    <div className="players">
                                        {gameStatus && gameStatus.players &&
                                            <>
                                                <div className="head">
                                                    {!gameStatus.completed && <span className="live">LIVE</span>}

                                                    <span className="total-players"><b>{gameStatus.players.length}</b> {gameStatus.players.length === 1 ? AppContext.r["player"] : AppContext.r["players"]} | </span>
                                                    <span className="total-viewers"><b>{gameStatus.totalViewers}</b> {gameStatus.totalViewers === 1 ? AppContext.r["viewer"] : AppContext.r["viewers"]} | </span>
                                                    <span>{AppContext.r["max-players-count"]} <b>{event.max_attendants_count}</b></span>

                                                    {(gameStatus.players && gameStatus.players.length > 0) ?
                                                        <Button variant="link" className="warning" onClick={() => { this.confirmResetScores() }}>
                                                            <i className="fas fa-trash-alt"></i> {AppContext.r["reset-scores"]}
                                                        </Button> : <br />}
                                                </div>

                                                <JsonToTable
                                                    head={this.jPlayersHead} body={gameStatus.players} />
                                            </>
                                        }
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>}
                </>
            );
        }
    }
}

export default withModal(withUderlyStore(QuizConsole));